import React from "react";
import { Formik, Form, FormikProps, FieldArray } from "formik";
import { Subdivision, blankSubdivison } from "../models/subdivision";
import { Alert, Container, Row, Col, Button, Card } from "react-bootstrap";
import { TextField, SelectField, CheckBox } from "./form-components/fields";
import * as Yup from "yup";
import { addSubdivision, updateSubdivision } from "../api/subdivision";
import { RouteChildrenProps } from "react-router";
import { listBuilders } from "../api/builder";
import { Builder } from "../models/builder";
import { Employee, newEmployee } from "../models/employee";
import { Employees } from "../api/employee";

const validateSchema = Yup.object().shape({
  company: Yup.object().shape({
    name: Yup.string().notRequired(),
    shortcode: Yup.string()
      .required("Must choose a Company")
      .max(2)
  }),
  name: Yup.string().required("Must have a name"),
  shortcode: Yup.string()
    .required("Must have a shortcode")
    .max(5, "Shortcode must be 5 characters")
    .min(5, "Shortcode must be 5 characters"),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required("Must set the type"),
        name: Yup.string().required("Must set a name"),
        email: Yup.string()
          .email("Enter a valid email")
          .required("Must have an email")
      })
    )
    .min(1, "Must have at least one contact"),
  assignies: Yup.array()
    .of(
      Yup.object().shape({
        kind: Yup.string().required(),
        employee: Yup.object().required()
      })
    )
    .min(1, "Must have an asignee")
});

interface SubdivisionFormProps extends RouteChildrenProps {
  value?: Subdivision;
  kind: string;
}

interface SubdivisionFormState {
  builderList: Builder[];
  employeeList: Employee[];
}

export class SubdivisionForm extends React.Component<
  SubdivisionFormProps,
  SubdivisionFormState
> {
  constructor(props: SubdivisionFormProps) {
    super(props);
    this.state = {
      builderList: [],
      employeeList: []
    };
  }

  componentDidMount = async () => {
    let bldrs = await listBuilders();
    let mngr = await Employees.list();
    this.setState({
      builderList: bldrs,
      employeeList: mngr
    });
  };

  render() {
    let subdivision = null;
    const employees = this.state.employeeList;
    if (
      this.props.kind === "Update" &&
      this.props.location.state.hasOwnProperty("subdivision")
    ) {
      subdivision = this.props.location.state.subdivision;
    }
    const builderOptions = this.state.builderList.map(v => {
      return {
        label: v.name,
        value: v.shortcode
      };
    });

    const employeeOptions = this.state.employeeList.map(v => {
      return {
        label: v.name,
        value: v.employeeId
      };
    });
    employeeOptions.push({
      label: "Select an Employee",
      value: ""
    });

    return (
      <Formik
        initialValues={subdivision ? subdivision : blankSubdivison()}
        onSubmit={(values, form) => {
          const bname = builderOptions.find(
            e => e.value === values.company.shortcode
          );
          if (bname) values.company.name = bname.label;
          values.updatedAt = new Date(Date.now()).toISOString();
          if (this.props.kind === "Create") {
            addSubdivision(values).then(res => {
              console.log(res);
              form.setSubmitting(false);
              this.props.history.push("/subdivision/list");
            });
          }
          if (this.props.kind === "Update") {
            updateSubdivision(values);
            form.setSubmitting(false);
            this.props.history.goBack();
          }
        }}
        validationSchema={validateSchema}
        validateOnChange
        render={(Fprops: FormikProps<Subdivision>) => (
          <Container>
            <Row className="mt-2">
              <Col>
                <h2>Subdivision: {Fprops.values.name}</h2>
              </Col>
            </Row>
            {Fprops.isSubmitting ? (
              <Row>
                <Alert variant="warning">Submitting</Alert>
              </Row>
            ) : null}
            <Row>
              <Col>
                <Alert
                  variant={
                    Fprops.values.calendarId &&
                    Fprops.values.calendarId.length > 0
                      ? "success"
                      : "warning"
                  }
                >
                  {Fprops.values.calendarId &&
                  Fprops.values.calendarId.length > 0
                    ? "Calendar has been created"
                    : "Calendar has not yet been created"}
                </Alert>
              </Col>
            </Row>
            <Form>
              <Row>
                <Col md="6">
                  <SelectField
                    name="company.shortcode"
                    label="Builder"
                    options={builderOptions}
                    onUpdate={e => {
                      Fprops.handleChange(e);
                      Fprops.setFieldValue("shortcode", e.target.value + "-");
                    }}
                  />
                  <TextField
                    name="company.shortcode"
                    label="Builder Shortcode"
                    disabled
                  />
                </Col>
                <Col md="6">
                  <TextField name="name" label="Subdivision Name" />
                  <TextField
                    name="shortcode"
                    label="Subdivision Shortcode"
                    disabled={this.props.kind === "Update"}
                  />
                  <CheckBox name="active" label="Active" />
                </Col>
              </Row>
              <FieldArray
                validateOnChange
                name="contacts"
                render={arrayHelpers => (
                  <React.Fragment>
                    <Row className=" mt-2">
                      <Col md="8">
                        <h4>Subdivision Contacts</h4>
                      </Col>
                      <Col md="2">
                        <Button
                          variant="success"
                          onClick={() => arrayHelpers.push({ type: "Builder" })}
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                    {typeof Fprops.errors.contacts === "string" ? (
                      <Alert variant="danger">{Fprops.errors.contacts}</Alert>
                    ) : null}
                    {Fprops.values.contacts &&
                    Fprops.values.contacts.length > 0 ? (
                      Fprops.values.contacts.map((ctn, idx) => (
                        <Card key={idx} className="mt-2">
                          <Card.Header>
                            {ctn.type} : {ctn.name}{" "}
                            <Button
                              className="float-right"
                              variant="danger"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              x
                            </Button>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md="3">
                                <SelectField
                                  name={`contacts[${idx}].type`}
                                  label="Kind"
                                  options={[
                                    { label: "Builder", value: "Builder" },
                                    {
                                      label: "Area Manager",
                                      value: "Area Manager"
                                    },
                                    {
                                      label: "Finish Builder",
                                      value: "Finish Builder"
                                    }
                                  ]}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  name={`contacts[${idx}].name`}
                                  label="Name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="7">
                                <TextField
                                  name={`contacts[${idx}].email`}
                                  label="Email"
                                />
                              </Col>
                              <Col md="5">
                                <TextField
                                  name={`contacts[${idx}].phone`}
                                  label="Phone"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <CheckBox
                                  name={`contacts[${idx}].alerts.email.postWalkFormSubmit`}
                                  label="Gets Post Walk Form emails"
                                />
                              </Col>
                              <Col>
                                <CheckBox
                                  name={`contacts[${idx}].alerts.sms.postWalkFormSubmit`}
                                  label="Gets Post Walk Form SMS"
                                />
                              </Col>
                              <Col>
                                <CheckBox
                                  name={`contacts[${idx}].contact-preferences.email.arrivalNotifications`}
                                  label="Gets Manager In/Out Notifications"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <h5>No Contacts</h5>
                    )}
                  </React.Fragment>
                )}
              />
              <hr />
              <FieldArray
                name="assignedTo"
                render={arrayHelpers => (
                  <React.Fragment>
                    <Row className="mt-5">
                      <Col md="8">
                        <h4>Assigned to</h4>
                      </Col>
                      <Col md="2">
                        <Button
                          variant="success"
                          onClick={() =>
                            arrayHelpers.push({
                              kind: "Manager",
                              employee: newEmployee()
                            })
                          }
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                    {typeof Fprops.errors.assignedTo === "string" ? (
                      <Alert variant="danger">{Fprops.errors.assignedTo}</Alert>
                    ) : null}
                    {Fprops.values.assignedTo &&
                    Fprops.values.assignedTo.length > 0
                      ? Fprops.values.assignedTo.map((emp, idx) => (
                          <Card key={idx} className="mt-2">
                            {console.log(emp, idx)}
                            <Card.Header>
                              {emp.kind} : {emp.employee.name}{" "}
                              <Button
                                className="float-right"
                                variant="danger"
                                onClick={() => arrayHelpers.remove(idx)}
                              >
                                x
                              </Button>
                            </Card.Header>
                            <Card.Body>
                              <Row>
                                <Col md="3">
                                  <SelectField
                                    name={`assignedTo[${idx}].kind`}
                                    label="Kind"
                                    options={[
                                      { label: "Manager", value: "Manager" },
                                      { label: "Punch", value: "Punch" }
                                    ]}
                                  />
                                </Col>
                                <Col>
                                  <SelectField
                                    name={`assignedTo[${idx}].employee.employeeId`}
                                    label="Employee"
                                    options={employeeOptions}
                                    onUpdate={e => {
                                      Fprops.handleChange(e);
                                      Fprops.setFieldValue(
                                        `assignedTo[${idx}].employee`,
                                        employees.filter(
                                          v => v.employeeId === e.target.value
                                        )[0]
                                      );
                                    }}
                                  />
                                </Col>
                              </Row>
                            </Card.Body>
                          </Card>
                        ))
                      : null}
                  </React.Fragment>
                )}
              />
              <hr />
              <Row className="mb-5">
                <Col md="6" className="mt-3">
                  <Button variant="outline-success" type="submit">
                    {this.props.kind}
                  </Button>
                </Col>
                <Col>
                  Last updated at:{" "}
                  {new Date(
                    Date.parse(Fprops.values.updatedAt)
                  ).toLocaleString()}
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      />
    );
  }
}
