import React from 'react';
import { Link, Route } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { SubdivisionForm } from '../components/SubdivisionForm';
import { Container, Row, Col, Nav, Jumbotron } from 'react-bootstrap';
import SubdivisionList from './SubdivisionList';

export const SubdivisionsPage = ({match} : RouteChildrenProps) => (
    <React.Fragment>
        <Container>
            <Nav variant="pills" className="justify-content-end">
                <Nav.Item><Link to={`${match!.url+ "/add"}`} ><Nav.Link href="/add" as="div">Add</Nav.Link></Link></Nav.Item>
                <Nav.Item><Link to={`${match!.url+ "/list"}`}><Nav.Link href="/list" as="div">List</Nav.Link></Link></Nav.Item>
            </Nav>
        </Container>
        <Route path={`${match!.url}`} exact component={SubdivisionHome}/>
        <Route path={`${match!.url}/add`}  render={(props) => <SubdivisionForm  {...props} kind="Create"/>}/>
        <Route path={`${match!.url}/list`}  render={(props) => <SubdivisionList {...props}/>}/>
        <Route path={`${match!.url}/edit/:shortcode`} render={(props) => <SubdivisionForm {...props} kind="Update" />} />
    </React.Fragment>
)


const SubdivisionHome = () => (
    <React.Fragment>
        <Container>
            <Row>
                <Col>
                    <Jumbotron>
                        <h2>Subdivision Menu</h2>
                        <p>Use the links at the top right to view or add subdivisions</p>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
)
