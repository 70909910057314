import { Subdivision } from "./subdivision";

export interface Employee {
    active: boolean;
    position: string;
    name: string;
    email: string;
    phone: string;
    driverId: string;
    employeeId: string;
    subdivisions: Subdivision[];
}

export const newEmployee = () : Employee => {
    return {
        active: true,
        name: "",
        position: "",
        employeeId: "",
        email: "",
        phone: "",
        driverId: "",
        subdivisions: []
    }
}