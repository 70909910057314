import React from 'react';
import { Jumbotron } from 'react-bootstrap';


export const Home = () => (
    <Jumbotron>
        <h1>Welcome to Residential Managemet</h1>
        <p>
            Check out the links up to to modify resources
        </p>
    </Jumbotron>
)