import Amplify, { API, Auth } from "aws-amplify";
import { Employee } from "../models/employee";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "residentialManager",
        endpoint: `https://${process.env.REACT_APP_API_PATH}`,
        service: "lambda",
        region: "us-east-1"
      }
    ]
  }
});

const list = async (): Promise<Employee[]> => {
  let apiName = "residentialManager";
  let path = "/employee";
  let session = await Auth.currentSession();
  let employees = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let sjs: Employee[] = employees;
  return Promise.resolve(sjs);
};

const add = async (mngr: Employee): Promise<Employee> => {
  let apiName = "residentialManager";
  let path = `/employee`;
  let session = await Auth.currentSession();
  let nEmployee = await API.post(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: mngr
  });
  let s: Employee = nEmployee;
  return Promise.resolve(s);
};

const update = async (mngr: Employee): Promise<Employee> => {
  let apiName = "residentialManager";
  let path = `/employee/${mngr.employeeId}`;
  let session = await Auth.currentSession();
  let uEmployee = await API.put(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: mngr
  });
  let s: Employee = uEmployee;
  return Promise.resolve(s);
};

const get = async (shortcode: string): Promise<Employee> => {
  let apiName = "residentialManager";
  let path = `/manager/${shortcode}`;
  let session = await Auth.currentSession();
  let employee = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let s: Employee = employee;
  return Promise.resolve(s);
};
export const Employees = {
  list,
  get,
  update,
  add
};
