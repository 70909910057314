import React from "react";
import { Link, Route } from "react-router-dom";
import { RouteChildrenProps } from "react-router";
import { Container, Row, Col, Nav, Jumbotron } from "react-bootstrap";
import { EmployeeForm } from "../components/EmployeeForm";
import ManagerList from "./EmployeeList";

export const EmployeePage = ({ match }: RouteChildrenProps) => (
  <React.Fragment>
    <Container>
      <Nav variant="pills" className="justify-content-end">
        <Nav.Item>
          <Link to={`${match!.url + "/add"}`}>
            <Nav.Link href="/add" as="div">
              Add
            </Nav.Link>
          </Link>
        </Nav.Item>
        <Nav.Item>
          <Link to={`${match!.url + "/list"}`}>
            <Nav.Link href="/list" as="div">
              List
            </Nav.Link>
          </Link>
        </Nav.Item>
      </Nav>
    </Container>
    <Route path={`${match!.url}`} exact component={EmployeeHome} />
    <Route
      path={`${match!.url}/add`}
      render={props => <EmployeeForm {...props} kind="Create" />}
    />
    <Route
      path={`${match!.url}/list`}
      render={props => <ManagerList {...props} />}
    />
    <Route
      path={`${match!.url}/edit/:shortcode`}
      render={props => <EmployeeForm {...props} kind="Update" />}
    />
  </React.Fragment>
);

const EmployeeHome = () => (
  <React.Fragment>
    <Container>
      <Row>
        <Col>
          <Jumbotron>
            <h2>Employee Menu</h2>
            <p>
              Use the links at the top right to edit or add residential
              employees (like Managers or Punch guys)
            </p>
          </Jumbotron>
        </Col>
      </Row>
    </Container>
  </React.Fragment>
);
