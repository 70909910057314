import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { Auth } from 'aws-amplify';


export const Topbar = () => (
    <Navbar sticky="top" bg="dark" variant="dark" expand="md">
        <Link to="/"><Navbar.Brand as="div">
            Residential Manager
        </Navbar.Brand></Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse>
            <Nav className="mr-auto">
            <Nav.Link as="div"><NavLink to="/subdivision" activeClassName='text-warning'>Subdivisions</NavLink></Nav.Link>
            <Nav.Link as="div"><NavLink to="/builder" activeClassName='text-warning'>Builders</NavLink></Nav.Link>
            <Nav.Link as="div"><NavLink to="/employee" activeClassName='text-warning'>Employees</NavLink></Nav.Link>
            </Nav>
        </Navbar.Collapse>
        <Button variant="outline-warning" onClick={() => Auth.signOut()} >Sign Out</Button>
    </Navbar>
)