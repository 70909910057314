import React from "react";
import { Formik, Form, FormikProps, FieldArray } from "formik";
import { TextField, SelectField, CheckBox } from "./form-components/fields";
import * as Yup from "yup";
import { Employee, newEmployee } from "../models/employee";
import { RouteChildrenProps } from "react-router";
import { Alert, Container, Row, Col, Button, Card } from "react-bootstrap";
import { Employees } from "../api/employee";

const managerValidationSchema = Yup.object().shape({
  employeeId: Yup.string().required("Employee ID is required"),
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Enter a valid email")
    .required("Email is required"),
  phone: Yup.string()
    .required("Phone is required")
    .min(10, "Please enter all the numbers")
    .max(11, "Please enter only numbers"),
  position: Yup.string().required("Position is required"),
  driverId: Yup.string().required("Driver ID is required")
});

interface EmployeeFormProps extends RouteChildrenProps {
  value?: Employee;
  kind: string;
}

interface EmployeeFormState {}

export class EmployeeForm extends React.Component<
  EmployeeFormProps,
  EmployeeFormState
> {
  fetchManager() {
    console.log(this.props.location.key);
  }

  render() {
    let employee = null;
    if (this.props.kind === "Update") {
      if (this.props.location.state.hasOwnProperty("employee")) {
        employee = this.props.location.state.employee;
      } else this.fetchManager();
    }
    return (
      <Formik
        initialValues={employee ? employee : newEmployee()}
        onSubmit={(values, form) => {
          Employees.update(values);
          form.setSubmitting(false);
          this.props.history.goBack();
        }}
        validationSchema={managerValidationSchema}
        validateOnChange
        render={({ values, isSubmitting }: FormikProps<Employee>) => (
          <Container>
            <Row>
              <Col>
                <h2>Employee: {values.name}</h2>
              </Col>
            </Row>
            {isSubmitting ? (
              <Row>
                <Alert variant="warning">Submitting</Alert>
              </Row>
            ) : null}
            <Form>
              <Row>
                <Col>
                  <CheckBox name="active" label="Active" />
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <TextField name="employeeId" label="Employee ID" />
                  <TextField name="name" label="Manager Name" />
                  <TextField name="position" label="Position" />
                </Col>
                <Col md="6">
                  <TextField name="email" label="Manager Email" />
                  <TextField name="phone" label="Manager Phone" />
                  <TextField name="driverId" label="Driver ID" />
                </Col>
              </Row>
              {values.subdivisions && values.subdivisions.length > 0 ? (
                values.subdivisions.map(subd => (
                  <Row>
                    <Col>
                      <Alert variant="info">
                        {subd.company.name} : {subd.name}
                      </Alert>
                    </Col>
                  </Row>
                ))
              ) : (
                <Row>
                  <Col>
                    <Alert variant="warning">No Subdivisions Assigned</Alert>
                  </Col>
                </Row>
              )}
              <Row className="mb-5">
                <Col md="6" className="mt-3">
                  <Button variant="outline-success" type="submit">
                    {this.props.kind}
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      />
    );
  }
}
