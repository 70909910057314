import React from 'react';
import { Link, Route } from 'react-router-dom';
import { RouteChildrenProps } from 'react-router';
import { Container, Row, Col, Nav, Jumbotron } from 'react-bootstrap';
import { BuilderForm } from '../components/BuilderForm';
import BuilderList from './BuilderList';

export const BuilderPage = ({match} : RouteChildrenProps) => (
    <React.Fragment>
        <Container>
            <Nav variant="pills" className="justify-content-end">
                <Nav.Item><Link to={`${match!.url+ "/add"}`} ><Nav.Link href="/add" as="div">Add</Nav.Link></Link></Nav.Item>
                <Nav.Item><Link to={`${match!.url+ "/list"}`}><Nav.Link href="/list" as="div">List</Nav.Link></Link></Nav.Item>
            </Nav>
        </Container>
        <Route path={`${match!.url}`} exact component={BuilderHome}/>
        <Route path={`${match!.url}/add`}  render={(props) => <BuilderForm  {...props} kind="Create"/>}/>
        <Route path={`${match!.url}/list`}  render={(props) => <BuilderList {...props}/>}/>
        <Route path={`${match!.url}/edit/:shortcode`} render={(props) => <BuilderForm {...props} kind="Update" />} />
    </React.Fragment>
)


const BuilderHome = () => (
    <React.Fragment>
        <Container>
            <Row>
                <Col>
                    <Jumbotron>
                        <h2>Builder Menu</h2>
                        <p>Use the links at the top right to view or add builders</p>
                    </Jumbotron>
                </Col>
            </Row>
        </Container>
    </React.Fragment>
)
