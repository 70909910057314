import React from "react";
import { Formik, Form, FormikProps, FieldArray } from "formik";
import { Alert, Container, Row, Col, Button, Card } from "react-bootstrap";
import { TextField, SelectField, CheckBox } from "./form-components/fields";
import * as Yup from "yup";
import { RouteChildrenProps, Redirect } from "react-router";
import { Builder, blankBuilder } from "../models/builder";
import { addBuilder, updateBuilder } from "../api/builder";

const validateSchema = Yup.object().shape({
  name: Yup.string().required("Must have a name"),
  shortcode: Yup.string()
    .required("Must have a shortcode")
    .max(2, "Shortcode must be 2 characters")
    .min(2, "Shortcode must be 2 characters"),
  contacts: Yup.array()
    .of(
      Yup.object().shape({
        type: Yup.string().required("Must set the type"),
        name: Yup.string().notRequired(),
        email: Yup.string()
          .email("Enter a valid email")
          .notRequired(),
        phone: Yup.string().notRequired()
      })
    )
    .min(1, "Must have at least one contact")
});

interface BuilderFormProps extends RouteChildrenProps {
  value?: Builder;
  kind: string;
}

interface BuilderFormState {
  subD?: Builder;
}

export class BuilderForm extends React.Component<
  BuilderFormProps,
  BuilderFormState
> {
  constructor(props: BuilderFormProps) {
    super(props);
  }

  render() {
    let builder = null;
    if (
      this.props.kind === "Update" &&
      this.props.location.state.hasOwnProperty("builder")
    ) {
      builder = this.props.location.state.builder;
    }

    return (
      <Formik
        initialValues={builder ? builder : blankBuilder()}
        onSubmit={(values, form) => {
          values.updatedAt = new Date(Date.now()).toISOString();
          if (this.props.kind === "Create") {
            addBuilder(values);
            // alert(JSON.stringify(values))
            form.setSubmitting(false);
          }
          if (this.props.kind === "Update") {
            updateBuilder(values);
            form.setSubmitting(false);
            this.props.history.goBack();
          }
        }}
        validationSchema={validateSchema}
        validateOnChange
        render={(Fprops: FormikProps<Builder>) => (
          <Container>
            <Row className="mt-2">
              <Col>
                <h2>Builder: {Fprops.values.name}</h2>
              </Col>
            </Row>
            {Fprops.isSubmitting ? (
              <Row>
                <Alert variant="warning">Submitting</Alert>
              </Row>
            ) : null}
            <Form>
              <Row>
                <Col md="6">
                  <TextField name="name" label="Builder Name" />
                  <TextField name="shortcode" label="Builder Shortcode" />
                  <CheckBox name="active" label="Active" />
                </Col>
                <Col>
                  <h3>Address</h3>
                  <TextField name="address.street1" label="Street" />
                  <TextField name="address.street2" label="Apt/Suite" />
                  <TextField name="address.city" label="City" />
                  <Row>
                    <Col md="3">
                      <TextField name="address.state" label="State" />
                    </Col>
                    <Col md="3">
                      <TextField name="address.zip" label="Zipcode" />
                    </Col>
                  </Row>
                </Col>
              </Row>
              <FieldArray
                validateOnChange
                name="contacts"
                render={arrayHelpers => (
                  <React.Fragment>
                    <Row className=" mt-2">
                      <Col md="8">
                        <h4>Builder Contacts</h4>
                      </Col>
                      <Col md="2">
                        <Button
                          variant="success"
                          onClick={() => arrayHelpers.push({ type: "Builder" })}
                        >
                          +
                        </Button>
                      </Col>
                    </Row>
                    {typeof Fprops.errors.contacts === "string" ? (
                      <Alert variant="danger">{Fprops.errors.contacts}</Alert>
                    ) : null}
                    {Fprops.values.contacts &&
                    Fprops.values.contacts.length > 0 ? (
                      Fprops.values.contacts.map((ctn, idx) => (
                        <Card key={idx} className="mt-2">
                          <Card.Header>
                            {ctn.type} : {ctn.name}{" "}
                            <Button
                              className="float-right"
                              variant="danger"
                              onClick={() => arrayHelpers.remove(idx)}
                            >
                              x
                            </Button>
                          </Card.Header>
                          <Card.Body>
                            <Row>
                              <Col md="3">
                                <SelectField
                                  name={`contacts[${idx}].type`}
                                  label="Kind"
                                  options={[
                                    {
                                      label: "Purchasing",
                                      value: "Purchasing"
                                    },
                                    { label: "Warranty", value: "Warranty" },
                                    { label: "Other", value: "Other" }
                                  ]}
                                />
                              </Col>
                              <Col>
                                <TextField
                                  name={`contacts[${idx}].name`}
                                  label="Name"
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md="7">
                                <TextField
                                  name={`contacts[${idx}].email`}
                                  label="Email"
                                />
                              </Col>
                              <Col md="5">
                                <TextField
                                  name={`contacts[${idx}].phone`}
                                  label="Phone"
                                />
                              </Col>
                            </Row>
                          </Card.Body>
                        </Card>
                      ))
                    ) : (
                      <h5>No Contacts</h5>
                    )}
                  </React.Fragment>
                )}
              />
              <hr />
              <Row className="mb-5">
                <Col md="6" className="mt-3">
                  <Button variant="outline-success" type="submit">
                    {this.props.kind}
                  </Button>
                </Col>
                <Col>
                  Last updated at:{" "}
                  {new Date(
                    Date.parse(Fprops.values.updatedAt)
                  ).toLocaleString()}
                </Col>
              </Row>
            </Form>
          </Container>
        )}
      />
    );
  }
}
