import React from "react";
import { Builder } from "../models/builder";
import { RouteChildrenProps } from "react-router";
import { Form, Row, Col, Container, Table, Button } from "react-bootstrap";
import { listBuilders } from "../api/builder";
import { Link } from "react-router-dom";

interface BuilderListState {
  builders: Builder[];
  name: string;
}

interface BuilderListProps extends RouteChildrenProps {}

export class BuilderList extends React.Component<
  BuilderListProps,
  BuilderListState
> {
  constructor(props: BuilderListProps) {
    super(props);
    this.state = {
      builders: [],
      name: ""
    };
  }

  componentDidMount = async () => {
    let bldrs = await listBuilders();
    this.setState({
      builders: bldrs
    });
  };

  setName = (e: any) => {
    this.setState({
      name: e.target.value
    });
  };

  render() {
    let subDs = this.state.builders!;
    subDs = subDs.filter(v =>
      v.name.toLowerCase().startsWith(this.state.name.toLowerCase())
    );
    subDs = subDs.sort((a, b) => (a.shortcode > b.shortcode ? 1 : -1));
    return (
      <Container>
        <Row>
          <Col>
            <h3>Builders</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Row>
                <Col md="4">
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control onChange={this.setName} />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover responsive="md">
              <thead>
                <tr>
                  <th>Active</th>
                  <th>Shortcode</th>
                  <th>Name</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {subDs.map((v, idx) => (
                  <tr key={idx} className={v.active ? "" : "bg-warning"}>
                    <td>{v.active ? "Yes" : "No"}</td>
                    <td>{v.shortcode}</td>
                    <td>{v.name}</td>
                    <td>
                      <Link
                        to={{
                          pathname: `/builder/edit/${v.shortcode}`,
                          state: {
                            builder: v
                          }
                        }}
                      >
                        <Button variant="warning">Edit</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BuilderList;
