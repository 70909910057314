import { Address, BuilderContact } from "./basetypes";

export interface Builder {
    active: boolean;
    shortcode: string;
    name: string;
    address: Address;
    contacts: BuilderContact[];
    updatedAt: string;
}


export const blankBuilder = () : Builder => {
    return {
        active: true,
        shortcode: "",
        name: "",
        address: {
            street1: "",
            street2: "",
            city: "",
            state: "",
            zip: ""
        },
        contacts: [],
        updatedAt: new Date(0).toISOString()
    }
}