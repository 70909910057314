import React, { Component } from "react";
import { Topbar } from "./components/topbar";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { SubdivisionsPage } from "./pages/Subdivisions";
import Amplify from "aws-amplify";
import awsmobile from "./aws-exports";
import { withAuthenticator } from "aws-amplify-react";
import { Home } from "./pages/Home";
import "./App.css";
import { BuilderPage } from "./pages/Builders";
import { EmployeePage } from "./pages/Employee";

Amplify.configure(awsmobile);

class mainPage extends Component {
  render() {
    return (
      <Router>
        <Topbar />
        <Route path="/subdivision" component={SubdivisionsPage} />
        <Route path="/builder" component={BuilderPage} />
        <Route path="/employee" component={EmployeePage} />
        <Route path="/" exact component={Home} />
      </Router>
    );
  }
}

let Page = withAuthenticator(mainPage);

class App extends Component {
  render() {
    return <Page />;
  }
}

export default App;
