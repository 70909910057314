// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:14c7f60f-0b73-4292-89ca-0f9800e87fc5",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_QNpbG2eyb",
    "aws_user_pools_web_client_id": "5i4svod3doee3d3ff5iujssha1",
    "oauth": {},
    "federationTarget": "COGNITO_IDENTITY_POOLS",
    "aws_content_delivery_bucket": "frontend-20190327211323-hostingbucket",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2rz7eg2sjb5xv.cloudfront.net"
};


export default awsmobile;
