import React from 'react';
import { Field, FieldProps, ErrorMessage } from 'formik';
import { Form, Alert, InputGroup } from 'react-bootstrap';
import { Subdivision } from '../../models/subdivision';

interface TextFieldProps {
    name: string;
    label: string;
    disabled?: boolean;
    value?: string;
    prepend?: string;
    onUpdate?(event: any) : void;
}


export const TextField = (props: TextFieldProps) => (
   
       <Form.Group>
        <ErrorMessage name={props.name} render={msg => (
            <Alert variant="danger">{msg}</Alert>
        )}/>
        <Field name={props.name} render={({field, form} : FieldProps<Subdivision>) => (
            <React.Fragment>
                <Form.Label>{props.label}</Form.Label>
                <InputGroup>
                    {props.prepend && props.prepend!.length > 0 && (
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                {props.prepend!}
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                    )}
                    <Form.Control as="input" {...field} disabled={props.disabled ? true : false} onChange={props.onUpdate ? props.onUpdate! : field.onChange}></Form.Control>
                </InputGroup>
            </React.Fragment>
        )}/>
        </Form.Group>
)


interface SelectFieldProps {
    name: string;
    label: string;
    options: {
        label: string;
        value: string;
    }[];
    onUpdate?(event: any) : void;
}

export const SelectField = (props : SelectFieldProps) => (
    <Form.Group>
        <ErrorMessage name={props.name} render={msg => (
            <Alert variant="danger">{msg}</Alert>
        )}/>
        <Field name={props.name} render={({field, form} : FieldProps<Subdivision>) => (
            <React.Fragment>
                <Form.Label>{props.label}</Form.Label>
                <Form.Control as="select" {...field} onChange={props.onUpdate ? props.onUpdate! : field.onChange}>
                {props.options.map((val, idx) => (
                    <option key={idx} value={val.value}>{val.label}</option>
                ))}
                </Form.Control>
            </React.Fragment>
        )}/>
    </Form.Group>
)


interface CheckBoxProps {
    name: string;
    label: string;
}

export const CheckBox = (props : CheckBoxProps) => (
    <Form.Group>
        <ErrorMessage name={props.name} render={msg => (
            <Alert variant="danger">{msg}</Alert>
        )}/>
        <Field name={props.name} render={({field, form} : FieldProps<Subdivision>) => (
            <React.Fragment>
                <Form.Check
                    type="checkbox"
                    label={props.label}
                    {...field}
                    checked={field.value === true}
                />
            </React.Fragment>
        )}/>
    </Form.Group>
)