import { Subdivision, blankSubdivison } from "../models/subdivision";
import Amplify, { API, Auth } from "aws-amplify";

console.log(process.env);

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "residentialManager",
        endpoint: `https://${process.env.REACT_APP_API_PATH}`,
        service: "lambda",
        region: "us-east-1"
      }
    ]
  }
});

export const listSubdivisions = async (): Promise<Subdivision[]> => {
  let apiName = "residentialManager";
  let path = "/subdivision";
  let session = await Auth.currentSession();
  let subDs = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let sjs: Subdivision[] = subDs;
  return Promise.resolve(sjs);
};

export const addSubdivision = async (sub: Subdivision) => {
  let apiName = "residentialManager";
  let path = `/subdivision`;
  let session = await Auth.currentSession();
  let subD = await API.post(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: sub
  });
  let s: Subdivision = subD;
  return Promise.resolve(s);
};

export const updateSubdivision = async (
  sub: Subdivision
): Promise<Subdivision> => {
  let apiName = "residentialManager";
  let path = `/subdivision/${sub.shortcode}`;
  let session = await Auth.currentSession();
  let subD = await API.put(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: sub
  });
  let s: Subdivision = subD;
  return Promise.resolve(s);
};

export const getSubdivision = async (
  shortcode: string
): Promise<Subdivision> => {
  let apiName = "residentialManager";
  let path = `/subdivision/${shortcode}`;
  let session = await Auth.currentSession();
  let subD = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let s: Subdivision = subD;
  return Promise.resolve(s);
};
