import React from "react";
import { Subdivision } from "../models/subdivision";
import { RouteChildrenProps } from "react-router";
import { Form, Row, Col, Container, Table, Button } from "react-bootstrap";
import { listSubdivisions } from "../api/subdivision";
import { Link } from "react-router-dom";
import { listBuilders } from "../api/builder";
import { Builder } from "../models/builder";
import { Employee } from "../models/employee";
import { Employees } from "../api/employee";

interface SubdivisionListState {
  subdivisions: Subdivision[];
  name: string;
  builder: string;
  manager: string;
  builders: Builder[];
  employees: Employee[];
}

interface SubdivisionListProps extends RouteChildrenProps {}

export class SubdivisionList extends React.Component<
  SubdivisionListProps,
  SubdivisionListState
> {
  constructor(props: SubdivisionListProps) {
    super(props);
    this.state = {
      subdivisions: [],
      name: "",
      builder: "",
      manager: "",
      builders: [],
      employees: []
    };
    this.setBuilder = this.setBuilder.bind(this);
    this.setManager = this.setManager.bind(this);
  }

  componentDidMount = async () => {
    let subD = await listSubdivisions();
    let bldrs = await listBuilders();
    let empls = await Employees.list();
    this.setState({
      subdivisions: subD,
      builders: bldrs,
      employees: empls
    });
  };

  setBuilder = (e: any) => {
    this.setState({
      builder: e.target.value
    });
  };

  setManager = (e: any) => {
    this.setState({
      manager: e.target.value
    });
  };

  setName = (e: any) => {
    this.setState({
      name: e.target.value
    });
  };

  render() {
    let subDs = this.state.subdivisions!;
    const builders = this.state.builders
      .map(v => v.name)
      .sort((a, b) => (a > b ? 1 : -1));
    const employees = this.state.employees
      .map(v => v.name)
      .sort((a, b) => (a > b ? 1 : -1));
    if (this.state.builder !== "") {
      subDs = subDs.filter(v => v.company.name === this.state.builder);
    }
    if (this.state.manager !== "") {
      subDs = subDs.filter(v => {
        return (
          v.assignedTo &&
          v.assignedTo.filter(em => {
            em.kind === "Manager" && em.employee.name === this.state.manager;
          }).length > 0
        );
      });
    }
    subDs = subDs.filter(v =>
      v.name.toLowerCase().startsWith(this.state.name.toLowerCase())
    );
    subDs = subDs
      .sort((a, b) => (a.name > b.name ? -1 : 1))
      .sort((a, b) => (a.company.name > b.company.name ? 1 : -1));
    return (
      <Container>
        <Row>
          <Col>
            <h3>Subdivisions</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Builder</Form.Label>
                    <Form.Control as="select" onChange={this.setBuilder}>
                      <option value="">Any</option>
                      {builders.map((v, idx) => (
                        <option key={idx} value={v}>
                          {v}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Manager</Form.Label>
                    <Form.Control as="select" onChange={this.setManager}>
                      <option value="">Any</option>
                      {employees.map((v, idx) => (
                        <option key={idx} value={v}>
                          {v}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Name</Form.Label>
                    <Form.Control onChange={this.setName} />
                  </Form.Group>
                </Col>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <Row>
          <Col>
            <Table striped bordered hover responsive="md">
              <thead>
                <tr>
                  <th>Active</th>
                  <th>Builder</th>
                  <th>Name</th>
                  <th>Manager</th>
                  <th>Edit</th>
                </tr>
              </thead>
              <tbody>
                {subDs.map((v, idx) => (
                  <tr key={idx} className={v.active ? "" : "bg-warning"}>
                    <td>{v.active ? "Yes" : "No"}</td>
                    <td>{v.company.name}</td>
                    <td>{v.name}</td>
                    <td>
                      {v.assignedTo && v.assignedTo!.length > 0
                        ? v.assignedTo![0].employee.name
                        : "No Manager"}
                    </td>
                    <td>
                      <Link
                        to={{
                          pathname: `/subdivision/edit/${v.shortcode}`,
                          state: {
                            subdivision: v
                          }
                        }}
                      >
                        <Button variant="warning">Edit</Button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default SubdivisionList;
