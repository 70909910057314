import { SubdivisionContact, SubdivisionCompany } from './basetypes';
import {Employee} from './employee'

export interface Subdivision {
    active: boolean;
    company: SubdivisionCompany;
    name: string;
    shortcode: string;
    contacts?: SubdivisionContact[];
    assignedTo?: {
        employee: Employee;
        kind: string;
    }[]
    calendarId?: string;
    updatedAt: string;
}


export const blankSubdivison = () => {
    let date = Date.now()
   let  newSub : Subdivision =  {
        active: true,
        company: {
            name: 'Select A Builder',
            shortcode: ''
        },
        name: '',
        shortcode: '',
        contacts : [
        ],
        assignedTo: [
        ],
        updatedAt: date.toLocaleString('en-US')
    }
    return newSub;
}