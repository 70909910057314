import React from 'react';
import { RouteChildrenProps } from 'react-router';
import { Form, Row, Col, Container, Table, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Employee } from '../models/employee';
import { Employees } from '../api/employee'

interface EmployeeListState {
    employees : Employee[]
    name: string;
}

interface EmployeeListProps extends RouteChildrenProps {
}


export class EmployeeList extends React.Component<EmployeeListProps, EmployeeListState> {
    
    constructor(props: EmployeeListProps) {
        super(props);
        this.state = {
            employees: [],
            name: ""
        }

    }

    componentDidMount = async () => {
        let empls = await Employees.list();
        this.setState({
            employees: empls
        })

    }

    setName = (e : any) => {
        this.setState({
            name: e.target.value
        })
    }

    render() {
        let employees = this.state.employees!
        employees = employees.filter(v => v.name.toLowerCase().startsWith(this.state.name.toLowerCase()))
        employees = employees.sort((a, b) => a.name > b.name ? -1 : 1)
        return (
            <Container>
                <Row>
                    <Col>
                        <h3>Employees</h3>
                    </Col>
                </Row>
                <Row>
                    <Col>
                    <Form>
                        <Form.Row>
                            <Col md="4">
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control onChange={this.setName}>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Form>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Table striped bordered hover responsive="md">
                        <thead>
                            <tr>
                                <th>Active</th>
                                <th>Name</th>
                                <th>Position</th>
                                <th>Email</th>
                                <th>Edit</th>
                            </tr>
                        </thead>
                        <tbody>
                            {employees.map((v, idx) => (
                                <tr key={idx}>
                                    <td>{v.active ? 'Yes' : 'No'}</td>
                                    <td>{v.name}</td>
                                    <td>{v.position}</td>
                                    <td>{v.email}</td>
                                    <td><Link to={{
                                        pathname: `/employee/edit/${v.employeeId}`,
                                        state: {
                                            employee: v
                                        }
                                    }}><Button variant="warning">Edit</Button></Link></td>
                                </tr>
                            ))}
                        </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default EmployeeList;