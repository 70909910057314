import Amplify, { API, Auth } from "aws-amplify";
import { Builder } from "../models/builder";

Amplify.configure({
  API: {
    endpoints: [
      {
        name: "residentialManager",
        endpoint: `https://${process.env.REACT_APP_API_PATH}`,
        service: "lambda",
        region: "us-east-1"
      }
    ]
  }
});

export const listBuilders = async (): Promise<Builder[]> => {
  let apiName = "residentialManager";
  let path = "/builder";
  let session = await Auth.currentSession();
  let builders = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let sjs: Builder[] = builders;
  return Promise.resolve(sjs);
};

export const addBuilder = async (sub: Builder): Promise<Builder> => {
  let apiName = "residentialManager";
  let path = `/builder`;
  let session = await Auth.currentSession();
  let nBuilder = await API.post(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: sub
  });
  let s: Builder = nBuilder;
  return Promise.resolve(s);
};

export const updateBuilder = async (bldr: Builder): Promise<Builder> => {
  let apiName = "residentialManager";
  let path = `/builder/${bldr.shortcode}`;
  let session = await Auth.currentSession();
  let uBuilder = await API.put(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    },
    body: bldr
  });
  let s: Builder = uBuilder;
  return Promise.resolve(s);
};

export const getBuilder = async (shortcode: string): Promise<Builder> => {
  let apiName = "residentialManager";
  let path = `/builder/${shortcode}`;
  let session = await Auth.currentSession();
  let builder = await API.get(apiName, path, {
    headers: {
      Authorization: session.getIdToken().getJwtToken()
    }
  });
  let s: Builder = builder;
  return Promise.resolve(s);
};
